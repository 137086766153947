import { podFulFillmentUtils } from '@thd-olt-component-react/fulfillment';

import { FULFILLMENT_METHODS } from './constants';

export const getFulfillmentLocation = ({ fulfillmentMethod, storeId = '', deliveryZip }) => {
  switch (fulfillmentMethod) {
  case FULFILLMENT_METHODS.STH:
    return 'DirectShip';
  case FULFILLMENT_METHODS.BOPIS:
  case FULFILLMENT_METHODS.STORE:
    return storeId || '';
  case FULFILLMENT_METHODS.BODFS:
  case FULFILLMENT_METHODS.APPLIANCE:
    return deliveryZip || '';
  default:
    return null;
  }
};

export const createPriorityOfFulfillments = (selectedFulfillment) => {
  const allFulfillments = [
    FULFILLMENT_METHODS.STH,
    FULFILLMENT_METHODS.BOPIS,
    FULFILLMENT_METHODS.STORE,
    FULFILLMENT_METHODS.BODFS
  ];

  if (selectedFulfillment) {
    allFulfillments.unshift(selectedFulfillment);
  }

  return Array.from(new Set(allFulfillments));
};

export const getRelatedFulfillmentMethods = (fulfillmentMethod) => {
  switch (fulfillmentMethod) {
  case FULFILLMENT_METHODS.STH:
  case FULFILLMENT_METHODS.STORE:
    return [FULFILLMENT_METHODS.STH, FULFILLMENT_METHODS.STORE];
  case FULFILLMENT_METHODS.BOPIS:
    return [FULFILLMENT_METHODS.BOPIS];
  case FULFILLMENT_METHODS.APPLIANCE:
    return [FULFILLMENT_METHODS.APPLIANCE];
  default:
    return [];
  }
};

export const updateAvailableFulfillments = (fulfillmentMethod = '', availableFulfillments = {}, quantity = 0) => {
  const updatedFulfillments = { ...availableFulfillments };

  if (quantity > 0) {
    getRelatedFulfillmentMethods(fulfillmentMethod)
      .forEach((relatedFulfillment) => {
        if (updatedFulfillments[relatedFulfillment] > 0) {
          updatedFulfillments[relatedFulfillment] -= quantity;
        }
      });
  }

  return updatedFulfillments;
};

export const getFulfillmentMethodFromCart = (promoCartItemEntry, isCartGraphQLEnabled) => {
  if (!isCartGraphQLEnabled) {
    return promoCartItemEntry.fulfillmentModel
      .find((ful) => ful.selected)?.fulfillmentMethod;
  }
  return promoCartItemEntry.product.fulfillment.fulfillmentOptions
    .find((ful) => {
      return ful?.services[0]?.selected;
    })?.type;

};

export const getCurrentlyAvailable = (promoCartItemModel, product) => {
  let availableFulfillments = podFulFillmentUtils.getAllFulfillmentInventories(product);

  Object.keys(promoCartItemModel?.fulfillments || {}).forEach((fulfillmentMethod) => {
    const qtyInCart = promoCartItemModel.fulfillments[fulfillmentMethod].quantity || 0;

    if (qtyInCart > 0) {
      availableFulfillments = updateAvailableFulfillments(fulfillmentMethod, availableFulfillments, qtyInCart);
    }
  });

  return availableFulfillments;
};

const locationHasQuantity = (location) => location.inventory?.quantity > 0;

const serviceHasLocationWithInventory = (service) => {
  return (service.locations || [])
    .some((location) => (location.isAnchor || location.locationId === '8119')
      && locationHasQuantity(location));
};

const fulfillmentOptionCanFulfill = (fulfillmentOption) => (
  (fulfillmentOption.services || []).some(serviceHasLocationWithInventory)
);

export const isFulfillable = (item) => (
  (item?.fulfillment?.fulfillmentOptions || []).some(fulfillmentOptionCanFulfill)
);

export const getFulfillmentLocationGraphQL = (item, storeId, deliveryZip) => {
  let ffmLocation = '';
  if (!item || !item.selectedFulfillment) {
    return ffmLocation;
  }
  const ffm = item.selectedFulfillment;
  switch (ffm) {
  case 'bopis':
    ffmLocation = storeId;
    break;
  case 'boss':
    ffmLocation = storeId;
    break;
  case 'bodfs':
    ffmLocation = deliveryZip;
    break;
  case 'sth':
    ffmLocation = deliveryZip;
    break;
  case 'appl':
    ffmLocation = deliveryZip;
    break;
  default:
  }
  return ffmLocation;
};
