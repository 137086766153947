import React from 'react';
import { element, string } from 'prop-types';
import { PromoWarningMessage } from './subcomponents/PromoWarningMessage/PromoWarningMessage';

export const CallToActionSection = ({
  children,
  warningMessage,
}) => (
  <div className="sui-grid sui-grid-cols-12" data-testid="promo-pod__cta">
    <div className="sui-col-span-3 sui-p-2">
      {warningMessage && <PromoWarningMessage message={warningMessage} />}
    </div>
    <div className="sui-col-span-8 sui-ml-3" style={{ gridColumn: "span 8 / span 8"}}>
      {children}
    </div>
  </div>
);

CallToActionSection.displayName = 'CallToActionSection';

CallToActionSection.propTypes = {
  children: element.isRequired,
  warningMessage: string,
};

CallToActionSection.defaultProps = {
  warningMessage: null
};
