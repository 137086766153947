import React, { useState } from 'react';
import {
  bool, func, number, string
} from 'prop-types';
import classNames from 'classnames';
import { useStoreId } from '@thd-nucleus/experience-context';
import { QueryProvider } from '@thd-nucleus/data-sources';
import { CardMedia } from '@one-thd/sui-atomic-components'
import { ErrorBoundary } from '@thd-olt-component-react/error-boundary';
import { usePromoCart } from '../../hooks/usePromoCart';
import { usePromoModel } from '../../hooks/usePromoModel';
import { QuickviewDrawer } from '../PromoDrawer/subcomponents/QuickviewDrawer';
import { usePromoPresentation } from '../../hooks/usePromoPresentation';
import { useProduct } from '../../hooks/useProduct';
import { PRODUCT_CTA_TYPE, HOME_DEPOT_QUOTE_CENTER,ASSETS_IMAGE_URL } from '../../utils/constants';
import { AddToCartCta } from '../AddToCartCta/AddToCartCta';
import { PromoSelectCta } from '../PromoSelectCta/PromoSelectCta';
import { SeeFullDetailsButton } from '../SeeFullDetailsButton/SeeFullDetailsButton';
import { CallToActionSection } from './subcomponents/CallToActionSection/CallToActionSection';
import { PromoPodPlaceholder } from './subcomponents/PromoPodPlaceholder';
import { noop } from '../../utils/promo-utils';

import './PromoPod.scss';
import { PromotionDrawerProductPod } from './PromotionDrawerProductPod';

import { useNewRelicLogging } from '../../hooks/useNewRelicLogging';

export const PromoPod = ({
  displayPosition,
  hideAtc,
  hideBorder,
  hideCaret,
  hideCta,
  hideKpf,
  hideQuickview,
  isInPromoList,
  itemId,
  onProductPodClick,
}) => {
  const storeId = useStoreId();
  const {
    analyticsSharedSection,
    isBogo,
    isForwardsB1gy,
    isForwardsBxg1,
    isDollarThresholdBogo,
    allListProductsAndAnchor,
    anchorItemId
  } = usePromoModel();
  const { isCartLoading, promoCartItemsObj } = usePromoCart();
  const { isRewardEnabled, type } = usePromoPresentation();
  const {
    isAppliance,
    product,
    productCtaType,
    selected,
    selectProduct,
    showPlaceholder
  } = useProduct(itemId, displayPosition);


  const { sendBogoCardDataToNewRelic } = useNewRelicLogging();
  const [renderQuickview, setRenderQuickview] = useState(false);
  const [quantityWarningText, setQuantityWarningText] = useState('');
  const isSecondaryListItem = product.listId === 'second';
  const isRewardItemDisabled = type === 'pod'
    && isSecondaryListItem && !isRewardEnabled && !(isForwardsB1gy || isForwardsBxg1 || isDollarThresholdBogo);
  const inCartQuantity = promoCartItemsObj?.[itemId]?.quantity || 0;
  const productPodMiniInCartQuantity = isBogo ? 0 : inCartQuantity;
  const ctaWarningMessage = isBogo ? null : quantityWarningText;
  const showKPF = !hideKpf && isAppliance;
  const showSeeFullDetails = productCtaType === PRODUCT_CTA_TYPE.seeFullDetails;
  const showPromoSelectCta = !hideCta && productCtaType === PRODUCT_CTA_TYPE.select;
  const showAddToCartCta = !hideAtc && productCtaType === PRODUCT_CTA_TYPE.addToCart;
  const productPodClick = (event) => {
    event.preventDefault(); // Prevents href navigation to the product information page
    const getFirstKeyInMap = (map) => Array.from(map)[0][0];
    const rewardItemId = getFirstKeyInMap(allListProductsAndAnchor);
    sendBogoCardDataToNewRelic({
      appName: 'nucleus.my-homepage',
      actionName: 'BOGO-PHP-AnchorClick',
      customerType: 'B2B',
      segment: 'Promotions',
      experience: 'BOGO',
      subExperience: 'BOGO/BOGO Percent Off/BOGO Dollar Off',
      anchorItemId,
      rewardItemId
    });
    onProductPodClick({ displayPosition, itemId });
  };
  const openQuickview = (event) => {
    if (event) event.preventDefault();
    /*
    you need to pass in the displayPosition and itemId,
    otherwise the drawer wont render
    the function that opens the quickview is in a higher level
    component, so we need to call the component that was passed to PromoPod
    */
    onProductPodClick({ displayPosition, itemId });
  };
  const onError = () => {
    // this function is a noop for the ErrorBoundary component
  };

  const showCallToActionSection = !hideCta && !isRewardItemDisabled;
  const callToActionSectionJSX = showCallToActionSection ? (
    <CallToActionSection warningMessage={ctaWarningMessage}>
      <>
        {showSeeFullDetails && <SeeFullDetailsButton itemId={itemId} />}
        {showPromoSelectCta && (
          <PromoSelectCta
            isSoldOut={product.isSoldOut}
            itemId={itemId}
            onSelect={selectProduct}
            selected={selected}
            variant="secondary"
          />
        )}
        {showAddToCartCta && (
          <AddToCartCta
            displayPosition={displayPosition}
            itemId={itemId}
            setWarningText={setQuantityWarningText}
            variant="secondary"
          />
        )}
      </>
    </CallToActionSection>
  ) : null;

  const promoPodClasses = classNames(
    'promo-pod',
    {
      'sui-m-0': !isInPromoList,
      'promo-pod--buybox': !isInPromoList,
      'promo-pod--selected': selected,
      'promo-pod--borderless': hideBorder
    }
  );

  if (!product && !showPlaceholder) return null;

  const isHDQCSku = product?.info?.productSubType?.name === HOME_DEPOT_QUOTE_CENTER;

  return (
    <section
      className={promoPodClasses}
      data-testid={`promo-pod-${itemId}`}
      id={`promo-pod-${itemId}`}
    >
      {showPlaceholder ?
        <PromoPodPlaceholder /> : <>
          {(isRewardItemDisabled) && (
              <div className="promo-pod__icon">
                <CardMedia src={`${ASSETS_IMAGE_URL}lock-icon-white.svg`} alt="lock" style={{ width: '10px', margin: 'auto', height:"10px" }} />
              </div>
          )}
          <ErrorBoundary id="id" name="productPod" onError={onError}>
            <PromotionDrawerProductPod
              itemId={itemId}
              storeId={storeId}
              key={itemId}
              position={displayPosition}
              onArrowClick={hideQuickview ? null : openQuickview}
              callToActionSection={callToActionSectionJSX}
              showKPF={showKPF}
              showDisabledATC
              hidePriceBadge
              disabled={isCartLoading}
              enableATC={false}
              inCartQuantity={productPodMiniInCartQuantity}
              onAction={productPodClick}
              silentATC
              hideCaret={hideCaret}
              sharedSection={analyticsSharedSection}
              showExactPriceOnLoad
              titleMaxLine={2}
              hideFulfillment
              isHDQCSku={isHDQCSku}
              hideBulkPrice={true}
              hideLimitPerOrder={false}
              showPreferredPricingBadge={false}
              showPreferredPricingBadgeToolTip={false}
              hideRebates={false}
              hideSavingsText={false}
              hideHighlights
              experienceUsesProductImageCarousel={
                {
                  "my-homepage": false,
                  "b2b": false,
                  "major-appliances": false,
                  "general-merchandise": false,
                  "hd-home": false
                }
              }
            />
          </ErrorBoundary>
          {renderQuickview &&
            <ErrorBoundary id="id" name="quickviewError" onError={onError}>
              <QueryProvider
                cacheKey="promo-products-quickview"
                defaultVariables={{ storeId, skipSubscribeAndSave: true }}
              >
                <QuickviewDrawer
                  onBack={() => { }}
                  onClose={() => { }}
                  onDrawerViewChange={() => { }}
                />
              </QueryProvider>
            </ErrorBoundary>
          }
        </>
      }
    </section>
  );
};

PromoPod.displayName = 'PromoPod';

PromoPod.propTypes = {
  displayPosition: number.isRequired,
  isInPromoList: bool,
  itemId: string.isRequired,
  hideKpf: bool,
  hideAtc: bool,
  hideCta: bool,
  hideCaret: bool,
  hideBorder: bool,
  hideQuickview: bool,
  onProductPodClick: func,
  hideHighlights: bool,
};

PromoPod.defaultProps = {
  isInPromoList: true,
  hideKpf: false,
  hideAtc: false,
  hideCta: false,
  hideCaret: false,
  hideBorder: false,
  hideQuickview: false,
  onProductPodClick: noop,
  hideHighlights: false,
};
