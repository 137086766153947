import React, {
  createContext,
  useContext,
  useMemo,
} from 'react';
import { bool, func, node, oneOf } from 'prop-types';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { usePromoCart } from '../hooks/usePromoCart';
import { usePromoModel } from '../hooks/usePromoModel';
import { getPreviewImages, isTouch } from '../utils/promo-presentation-utils';
import { DOLLAR_THRESHOLD_BOGO_SUBEXPERIENCE_TAGS, FORWARDS_BOGO_SUBEXPERIENCE_TAGS } from '../utils/constants';

export const defaultPresentationContext = {
  firstListPreviewImages: [],
  secondListPreviewImages: [],
  isTouchDevice: false,
  isDrawerOpen: false,
  isSummaryDrawerOpen: false,
  isRewardEnabled: false,
  isSummaryPopoverOpen: false,
};

export const PromoPresentationContext = createContext(defaultPresentationContext);

export const PromoPresentationProvider = ({
  children,
  isDrawerOpen,
  setIsDrawerOpen,
  isSummaryDrawerOpen,
  setIsSummaryDrawerOpen,
  setIsSummaryPopoverOpen,
  isSummaryPopoverOpen,
  type
}) => {
  const { channel } = useContext(ExperienceContext);
  const {
    allListProductsAndAnchor,
    displayableFirstListItemIds,
    displayableSecondListItemIds,
    isBmsm,
    isMultiSelect,
    rewardTiers,
    src1EligibilityCriteria,
    subExperience,
  } = usePromoModel();
  const {
    currentPromoTier,
    firstListCartQuantity,
    promoCartValue,
    selectedItemsModel,
  } = usePromoCart();

  const {
    minPurchaseAmount: tieredMinPurchaseAmount,
  } = rewardTiers[currentPromoTier];

  let isRewardEnabled = false;
  switch (subExperience) {
  case FORWARDS_BOGO_SUBEXPERIENCE_TAGS.buyOneGetY:
  case FORWARDS_BOGO_SUBEXPERIENCE_TAGS.buyOneGetYDollarOff:
  case FORWARDS_BOGO_SUBEXPERIENCE_TAGS.buyOneGetYPercentageOff:
  case FORWARDS_BOGO_SUBEXPERIENCE_TAGS.buyXGetOne:
  case FORWARDS_BOGO_SUBEXPERIENCE_TAGS.buyXGetOnePercentageOff:
  case FORWARDS_BOGO_SUBEXPERIENCE_TAGS.buyXGetOneDollarOff:
  {
    isRewardEnabled = firstListCartQuantity >= src1EligibilityCriteria.minPurchaseQuantity;
    break;
  }
  case DOLLAR_THRESHOLD_BOGO_SUBEXPERIENCE_TAGS.buyMinAmountGetOne:
  case DOLLAR_THRESHOLD_BOGO_SUBEXPERIENCE_TAGS.buyMinAmountGetDollarOff:
  case DOLLAR_THRESHOLD_BOGO_SUBEXPERIENCE_TAGS.buyMinAmountGetPercentageOff: {
    isRewardEnabled = src1EligibilityCriteria.minPurchaseAmount > 0
      && promoCartValue >= src1EligibilityCriteria.minPurchaseAmount;
    break;
  }
  default: {
    isRewardEnabled = tieredMinPurchaseAmount > 0 && promoCartValue >= tieredMinPurchaseAmount;
    break;
  }
  }

  const isTouchDevice = isTouch();

  const showWarning = isBmsm && isMultiSelect && selectedItemsModel?.length > 0;

  const contextValue = useMemo(() => {
    const firstListPreviewImages = getPreviewImages({
      allListProductsAndAnchor,
      channel,
      itemIds: displayableFirstListItemIds,
      type
    });
    const secondListPreviewImages = getPreviewImages({
      allListProductsAndAnchor,
      channel,
      itemIds: displayableSecondListItemIds,
      type
    });
    return {
      firstListPreviewImages,
      secondListPreviewImages,
      isTouchDevice,
      isDrawerOpen,
      isSummaryDrawerOpen,
      isRewardEnabled,
      setIsDrawerOpen,
      setIsSummaryDrawerOpen,
      isSummaryPopoverOpen,
      setIsSummaryPopoverOpen,
      showWarning,
      type,
    };
  }, [
    allListProductsAndAnchor,
    channel,
    displayableFirstListItemIds,
    displayableSecondListItemIds,
    isTouchDevice,
    isDrawerOpen,
    isSummaryDrawerOpen,
    isSummaryPopoverOpen,
    isRewardEnabled,
    setIsDrawerOpen,
    setIsSummaryDrawerOpen,
    setIsSummaryPopoverOpen,
    showWarning,
    type
  ]);

  return (
    <PromoPresentationContext.Provider value={contextValue}>
      { children }
    </PromoPresentationContext.Provider>
  );
};

PromoPresentationProvider.propTypes = {
  children: node.isRequired,
  isDrawerOpen: bool.isRequired,
  setIsDrawerOpen: func.isRequired,
  isSummaryDrawerOpen: bool.isRequired,
  setIsSummaryDrawerOpen: func.isRequired,
  isSummaryPopoverOpen: bool.isRequired,
  setIsSummaryPopoverOpen: func.isRequired,
  type: oneOf(['card', 'pod']).isRequired
};
