import React, { useContext } from 'react';
import { string, number } from 'prop-types';
import { LoadingButton } from '@one-thd/sui-atomic-components';
import { ExperienceContext, useStoreId, useConfigService } from '@thd-nucleus/experience-context';

import { useMessage } from '../../hooks/useMessage';
import { usePromoCart } from '../../hooks/usePromoCart';
import { usePromoModel } from '../../hooks/usePromoModel';
import { MESSAGE_KEY, FEATURE_SWITCH_KEYS } from '../../utils/constants';
import {
  buildAddToCartRequest,
  buildAnalyticsCartEventObject,
  calculateFulfillmentTypeOption
} from '../../utils/promo-cart-utils';
import { podFulFillmentUtils } from '@thd-olt-component-react/fulfillment';

export const AddToCartButton = ({ displayPosition, itemId, variant }) => {
  const { deliveryZip } = useContext(ExperienceContext);
  const {
    promoCartItemsObj,
    isCartLoading,
    addToCart,
    firstListCartQuantity,
    secondListCartQuantity,
  } = usePromoCart();
  const {
    allListProductsAndAnchor,
    analyticsSharedSection,
    applianceDeliveryStore,
    displayableFirstListItemIds,
    src1EligibilityCriteria,
    tgt1EligibilityCriteria,
    isForwardsB1gy,
    isForwardsBxg1
  } = usePromoModel();
  const storeId = useStoreId();
  const addToCartButtonText = useMessage(MESSAGE_KEY.addToCartButton);
  const soldOutButtonText = useMessage(MESSAGE_KEY.soldOutButton);
  const isCartGraphQLEnabled = useConfigService(FEATURE_SWITCH_KEYS.enableCartGraphQL) || false;
  const product = allListProductsAndAnchor.get(itemId);

  const promoItemModel = promoCartItemsObj[itemId];

  const {getFulfillmentMethod} = podFulFillmentUtils;

  const handleCartClick = () => {
    const cartRequest = buildAddToCartRequest({
      product: { ...product, displayPosition },
      promoItemModel: promoItemModel || {},
      desiredQuantity: 1,
      storeId,
      applianceDeliveryStore,
      deliveryZip,
      isCartGraphQLEnabled,
      selectedFulfillment: getFulfillmentMethod(product, false) || ''
    });

    addToCart(cartRequest)
      .then((cartResponse) => {
        window.LIFE_CYCLE_EVENT_BUS.trigger('promotion-products-add-to-cart.success', {
          cartReqParams: calculateFulfillmentTypeOption(cartRequest.cartRequest, cartRequest?.CartRequest),
          cartOptions: { sharedSection: analyticsSharedSection, component: 'product pod' },
          cartInfo: buildAnalyticsCartEventObject(cartRequest, cartResponse, isCartGraphQLEnabled),
        });
      });
  };
  const isQualifyingItem = displayableFirstListItemIds?.includes(itemId);
  const qualifyingThresholdMet = src1EligibilityCriteria?.minPurchaseQuantity <= firstListCartQuantity;
  const rewardThresholdMet = tgt1EligibilityCriteria?.minPurchaseQuantity <= secondListCartQuantity;
  const disableAtcForBogo = (isForwardsB1gy || isForwardsBxg1)
    && (isQualifyingItem ? qualifyingThresholdMet : rewardThresholdMet);
  return (
    <div className="sui-w-full">
      <LoadingButton
        disabled={product.isSoldOut || disableAtcForBogo}
        fullWidth
        loading={isCartLoading}
        onClick={handleCartClick}
        variant={variant}
      >
        {product.isSoldOut ? soldOutButtonText : addToCartButtonText}
      </LoadingButton>
    </div>
  );
};

AddToCartButton.displayName = 'AddToCartButton';

AddToCartButton.propTypes = {
  displayPosition: number.isRequired,
  itemId: string.isRequired,
  variant: string
};

AddToCartButton.defaultProps = {
  variant: 'primary'
};
